import React from 'react';
import NextLink from 'next/link';
import { Link } from 'rebass';
import PropTypes from 'prop-types';

function RouterLink({ href, as, children, ...props }) {
  return (
    <Link as={NextLink} href={as || href} {...props}>
      {children}
    </Link>
  );
}

RouterLink.propTypes = {
  href: PropTypes.string.isRequired,
  as: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

RouterLink.defaultProps = {
  as: null,
};

export default RouterLink;
