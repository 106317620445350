import { useMemo } from 'react';

export const useHomeRoute = () => '/';
export const useCelebrationRoute = (id, page = '', isFromMobile = false) =>
  useMemo(() => {
    // if (isFromMobile) return { href: `/dashboard/[id]`, as: `/dashboard/${id}` };
    if (page === 'home') return { href: `/`, as: `/` };
    if (page) return { href: `/dashboard/[id]/${page}`, as: `/dashboard/${id}/${page}` };
    return { href: `/dashboard/[id]`, as: `/dashboard/${id}` };
  }, [id, page, isFromMobile]);

export const useNewCelebrationRoute = (id, page = '') =>
  useMemo(() => {
    if (page)
      return {
        href: `/new/[id]/${page}`,
        as: `/new${id ? `/${id}` : ''}${page ? `/${page}` : ''}`,
      };
    return { href: `/new/[id]`, as: `/new${id ? `/${id}` : ''}` };
  }, [id, page]);
