import React, { useCallback, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Heading, Button, Text, Box } from 'rebass';
import { useWindowSize } from 'react-use';
import AnimatedDialog from '@/components/app/AnimatedDialog';
import Grid from '@/components/app/Grid';

function ConfirmDialog({
  isOpen,
  title,
  body,
  confirmVariant,
  onConfirm,
  onDismiss,
  loading,
  children,
  cancelTitle,
  confirmTitle,
  bgButtonColor,
  px = 7,
  ...props
}) {
  const { width } = useWindowSize();
  const isForMobile = width < 500;
  const [state, dispatch] = useReducer((initState, action) => {
    switch (action.type) {
      case 'toggle':
        return action.open || !initState;
      default:
        return initState;
    }
  });

  const toggle = useCallback((open = true) => {
    dispatch({ type: 'toggle', open });
  });

  const show = useMemo(() => {
    if (typeof children === 'function') return state;
    return isOpen;
  }, [isOpen, state, children]);

  return (
    <>
      <AnimatedDialog
        contentProps={{ width: isForMobile ? '100vw' : '80vw', maxWidth: cancelTitle ? 425 : 640 }}
        isOpen={show}
        onDismiss={onDismiss}
        {...props}
      >
        <Box flex={1}>
          <Box
            textAlign="center"
            pb={isForMobile ? 2 : 5}
            pt={isForMobile ? 2 : 3}
            px={isForMobile ? 2 : px}
          >
            <Heading fontFamily="body">{title}</Heading>
            {body && (
              <Text pt={1} color="lightBody" fontSize={1}>
                {body}
              </Text>
            )}
          </Box>
          {onConfirm && (
            <Grid
              gridTemplateColumns="1fr 1fr"
              justifyContent="end"
              p={3}
              gridGap={3}
              borderTop="1px solid"
              borderColor="border"
            >
              <Button
                variant={cancelTitle ? 'outline' : 'muted'}
                justifySelf="end"
                type="button"
                onClick={onDismiss}
                disabled={loading}
                p={[1, 2, 3]}
              >
                {cancelTitle || 'Cancel'}
              </Button>
              <Button
                variant={confirmVariant}
                justifySelf="start"
                type="button"
                onClick={onConfirm}
                disabled={loading}
                sx={{ background: confirmTitle ? '#B70C0C' : bgButtonColor }}
                p={[1, 2, 3]}
              >
                {confirmTitle || 'Confirm'}
              </Button>
            </Grid>
          )}
        </Box>
      </AnimatedDialog>
      {typeof children === 'function' && children({ toggle })}
    </>
  );
}

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmVariant: PropTypes.string,
};
ConfirmDialog.defaultProps = {
  body: '',
  confirmVariant: 'destructive',
};

export default ConfirmDialog;

export function ConfirmButton({ buttonProps, children, isOpen, onConfirm, setIsOpen, ...props }) {
  return (
    <>
      <ConfirmDialog
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        onConfirm={onConfirm}
        {...props}
      />
      {children && (
        <Button type="button" onClick={() => setIsOpen(true)} {...buttonProps}>
          {children}
        </Button>
      )}
    </>
  );
}
ConfirmButton.propTypes = {
  buttonProps: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
ConfirmButton.defaultProps = { buttonProps: {} };
