import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { MdCheck } from 'react-icons/md';

function CheckBox({
  active,
  color,
  activeBg,
  border = `1px solid #D9D9D9`,
  sx,
  onClick,
  disabled,
  bg = '#FAFAFA',
}) {
  return (
    <Box
      sx={{
        background: active ? activeBg : bg,
        opacity: 1,
        minWidth: 24,
        height: 24,
        border,
        boxSizing: 'border-box',
        borderRadius: '2px',
        marginRight: '10px',
        color,
        cursor: 'pointer',
        ...sx,
      }}
      disabled={disabled}
      onClick={onClick}
      as={active && MdCheck}
    />
  );
}

CheckBox.propTypes = {
  active: PropTypes.bool,
  sx: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  activeBg: PropTypes.string,
};
CheckBox.defaultProps = {
  active: false,
  sx: {},
  color: 'white',
  activeBg: '#000000',
  onClick: () => {},
  disabled: false,
};

export default CheckBox;
