import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import { IoIosArrowBack } from 'react-icons/io';
import { Flex, Button, Text, Box } from 'rebass';
import { useMobileCheckHook } from '@/hooks/celebration';
import OutlineButton from './app/OutlineButton';
import { useCelebrationForm } from './CelebrationForm/CelebrationFormContext';

export function CelebrationLayoutFooter({
  onNextPress,
  onBack,
  type = '',
  nextButtonText,
  disabled = false,
  sx = {},
  onSkipPress,
  notChangeable = false,
  onBackDisabled = false,
}) {
  const {
    query: { id },
  } = useRouter();
  const formik = useFormikContext();
  const isFromMobile = useMobileCheckHook();
  const [loading] = useCelebrationForm();

  return (
    <Flex
      justifyContent="center"
      alignItems={isFromMobile || onBack ? 'flex-end' : 'baseline'}
      sx={{
        position: 'fixed',
        bottom: 0,
        pb: isFromMobile && id ? '70px' : [2, 2],
        width: '100%',
        background: 'white',
        zIndex: 3,
        pt: [2, 2],
        bg: '#F0F0F0',
        ...sx,
      }}
    >
      {onBack && (
        <Flex
          mr={[5, 9]}
          height={60}
          justifyContent="space-around"
          alignItems="center"
          bg="#F4F6F7"
          sx={{ borderRadius: 'medium', cursor: onBackDisabled ? 'not-allowed' : 'pointer' }}
          p="0px 16px"
          onClick={onBackDisabled ? undefined : onBack}
          disabled={onBackDisabled}
        >
          <Text as={IoIosArrowBack} color="black" fontSize={20} />
          {!isFromMobile && (
            <Text color="black" fontWeight={500} fontSize="20px">
              {' '}
              Back
            </Text>
          )}
        </Flex>
      )}
      <Flex flexDirection="column">
        {isFromMobile && onSkipPress && <OutlineButton>Skip for now</OutlineButton>}
        <Box>
          {notChangeable && (
            <Text
              sx={{
                color: '#565656',
                fontSize: ['16px', '21px'],
                lineHeight: '130%',
                fontWeight: '400 !important',
                pt: 1,
              }}
            >
              👇 Note you CANNOT change this later
            </Text>
          )}
          <Button
            height={60}
            variant="special"
            type={type}
            onClick={onNextPress}
            disabled={disabled || formik?.isSubmitting || loading}
            minWidth={240}
          >
            {nextButtonText}
          </Button>
        </Box>
        {!isFromMobile && onSkipPress && (
          <Text
            mt={2}
            onClick={onSkipPress}
            color="#7B33FB"
            fontSize="20px"
            fontWeight={400}
            lineHeight="120%"
            sx={{
              background: 'linear-gradient(90deg, #DC2C77 0%, #7B5CDE 105.43%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              cursor: 'pointer',
            }}
          >
            Skip for now
          </Text>
        )}
      </Flex>
    </Flex>
  );
}

function CreateCelebrationLayout({
  children,
  bgImage,
  current,
  nextButtonText = 'Next',
  backgroundSize = ['100vw 50vh', '100vw 60vh'],
  noFooter = false,
  onBack = () => {},
  onNextPress = () => {},
  px = 3,
}) {
  return (
    <Flex
      width="100vw"
      height={current === 5 ? undefined : '100vh'}
      color="white"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      px={px}
      sx={{
        backgroundImage: `url("${bgImage}")`,
        backgroundSize,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
    >
      {children}
      {!noFooter && (
        <CelebrationLayoutFooter
          onBack={onBack}
          onNextPress={onNextPress}
          nextButtonText={nextButtonText}
        />
      )}
    </Flex>
  );
}
CreateCelebrationLayout.propTypes = {
  children: PropTypes.node,
};
CreateCelebrationLayout.defaultProps = { children: <Flex /> };

export default CreateCelebrationLayout;
