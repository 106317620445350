import React, { useContext, useMemo, useState, createContext } from 'react';

export const CelebrationFormContext = createContext({});

export const useCelebrationForm = () => {
  const [state, dispatch] = useContext(CelebrationFormContext);
  const layer = useMemo(() => [state, dispatch], [state, dispatch]);
  return layer;
};

export function CelebrationFormList({ children }) {
  const [loading, setLoading] = useState(false);
  const layer = useMemo(() => [loading, setLoading], [loading, setLoading]);
  return (
    <CelebrationFormContext.Provider value={layer}>{children}</CelebrationFormContext.Provider>
  );
}
