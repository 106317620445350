import React from 'react';
import { Flex, Box, Text } from 'rebass';

export function BorderedBox({ children, sx = {}, ...props }) {
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        borderRadius: '19.164px',
        border: '0.958px solid #E7EBEE',
        p: '22px 30px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Flex>
  );
}

export function LightBlueBox({ sx = {}, children, ...props }) {
  return (
    <Box
      sx={{
        borderRadius: '20px',
        background: '#F3F6FD',
        p: '6px 12px',
        minWidth: '100px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function Span({ sx = {}, children, ...props }) {
  return (
    <Text
      sx={{
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: '-0.396px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Text>
  );
}

export function HomePageBG({ fill = '#EBF0FE' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="933"
      height="160"
      viewBox="0 0 933 160"
      fill="none"
    >
      <path
        d="M0 0H933C933 88.3656 861.366 160 773 160H160C71.6345 160 0 88.3656 0 0Z"
        fill={fill}
      />
    </svg>
  );
}

export function HomePageBGMobile({ fill = '#EBF0FE' }) {
  return (
    <svg
      width="100vw"
      height="230"
      viewBox="0 0 100vw 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="-1" width="100vw" height="230" fill={fill} />
    </svg>
  );
}

export function HomePageBGMobileWave({ width }) {
  return (
    <svg
      style={{ marginTop: '-29px' }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="29"
      viewBox={`0 0 ${width} 29`}
      fill="none"
    >
      <path
        d={`M0 11.6785C51.3622 -1.28534 104.929 -2.92434 156.988 6.87514L192.7 13.5975C246.917 23.8033 302.395 25.4884 357.131 18.5917L${width} 11.6785V29H0V11.6785Z`}
        fill="white"
      />
    </svg>
  );
}

export function UpArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="16" viewBox="0 0 61 16" fill="none">
      <path
        d="M0.5 14.5C0.223858 14.5 0 14.7239 0 15C0 15.2761 0.223858 15.5 0.5 15.5V14.5ZM57.5 15V15.5H58V15H57.5ZM57.5 0.5L54.6132 5.5H60.3868L57.5 0.5ZM0.5 15.5H57.5V14.5H0.5V15.5ZM58 15V5H57V15H58Z"
        fill="black"
      />
    </svg>
  );
}

export const ctaStyle = {
  width: '145px',
  borderRadius: '100px',
  border: '1px solid #F2EBFF',
  background: '#F2EBFF',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '140%',
  letterSpacing: '-0.352px',
  color: '#7B33FB',
  p: '5px 15px',
};

export const SectionStyle = {
  borderRadius: 'xLarge',
  bg: 'lightBlue',
  p: '20px 24px',
};

export const OutlineButtonStyle = {
  borderRadius: '20px',
  border: '1.308px solid #F2EBFF',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '140%',
  letterSpacing: '-0.352px',
  color: '#7B33FB',
  p: '5px 15px',
  bg: 'white',
};
