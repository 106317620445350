import React from 'react';
import { useRouter } from 'next/router';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FiCheckCircle } from 'react-icons/fi';
import { Input } from '@rebass/forms';
import { FaLink } from 'react-icons/fa';
import { Box, Flex, Text, Button } from 'rebass';
import { IoMdLink } from 'react-icons/io';
import { usePublicCelebrationVideoURL, useCelebration } from '@/hooks/celebration';
import { useCreateConfirmationManual } from '@/components/app/ConfirmationOverlayManual';
import {
  SectionStyle,
  Span,
  UpArrow,
} from '@/components/CelebrationHomeScreen/common/ReusableComponents';

function CopyVideoLink({ celebrationId, pt = 1, newDesign = false }) {
  const {
    query: { id },
  } = useRouter();
  const celebration = useCelebration(celebrationId || id);
  const publicVideoLink = usePublicCelebrationVideoURL(celebration.slug);
  const createConfirmation = useCreateConfirmationManual();
  const isNotVGB = false;

  if (newDesign)
    return (
      <Box sx={SectionStyle}>
        <Flex flexDirection="column" mb="20px" alignItems="center" mt="-40px">
          <Box sx={{ bg: '#F3F6FD', borderRadius: '10px', p: 1 }}>
            <FaLink size={24} />
          </Box>
          <Text
            textAlign="center"
            sx={{
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '120%',
              letterSpacing: '-0.44px',
              color: 'purples.10',
            }}
          >
            {celebration.state === 'created'
              ? "This option will be available after you've created a merged video"
              : 'Share the link below with your friends and family!'}
          </Text>
        </Flex>

        {celebration.state !== 'created' && (
          <CopyToClipboard
            text={publicVideoLink}
            onCopy={() =>
              createConfirmation({
                icon: FiCheckCircle,
                message: 'Link copied and is ready to paste into an email or text message',
              })
            }
          >
            <Flex
              sx={{
                border: isNotVGB ? '1px dashed #342E57' : '2px dashed #342E57',
                borderRadius: isNotVGB ? '100px' : '8px',
                background: '#fff',
              }}
            >
              <Flex
                alignItems="center"
                sx={{
                  p: 2,
                  background: '#fff',
                  border: 0,
                  fontWeight: 700,
                  width: ['calc(100vw - 168px)', '100%'],
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  borderRadius: isNotVGB ? '100px' : '8px',
                  color: '#13C283',
                }}
              >
                {publicVideoLink}
              </Flex>
              <Button
                bg={isNotVGB ? '#13C283' : '#fff'}
                width={isNotVGB && '180px'}
                sx={{ borderRadius: isNotVGB ? '100px' : '8px', display: isNotVGB && 'flex' }}
              >
                <Text as={IoMdLink} color="#13C283" size={24} />
              </Button>
            </Flex>
          </CopyToClipboard>
        )}
        <Flex mt={1}>
          <Span
            mr={1}
            sx={{
              fontSize: '18px',
              fontStyle: 'italic',
              letterSpacing: '-0.396px',
              lineHeight: '120%',
              fontWeight: 500,
            }}
          >
            tap to copy link
          </Span>
          <UpArrow />
        </Flex>
        <Text
          mt={3}
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '120%',
            letterSpacing: '-0.352px',
            color: '#848F95',
            mb: 2,
          }}
        >
          The link will be active for 12 months.
        </Text>
      </Box>
    );

  return (
    <Box pt={pt} width="100%">
      <Flex flexDirection="column" textAlign="left" mb={3}>
        <Text
          fontWeight={400}
          fontSize={18}
          color="#505050"
          mb={2}
          lineHeight="130%"
          textAlign="left"
        >
          {celebration.state === 'created'
            ? "This option will be available after you've created a merged video"
            : 'Share the link below with your friends and family!'}
        </Text>
        {celebration.state !== 'created' && (
          <CopyToClipboard
            text={publicVideoLink}
            onCopy={() =>
              createConfirmation({
                icon: FiCheckCircle,
                message: 'Link copied and is ready to paste into an email or text message',
              })
            }
          >
            <Flex
              sx={{
                border: '2px dotted',
                background: '#F5F5F5',
                borderImage: 'linear-gradient(90deg, #DC2C77 0%, #7B5CDE 105.43%) 1',
                borderRadius: '8px',
              }}
            >
              <Input
                disabled
                value={publicVideoLink}
                autoFocus={false}
                tabIndex={-1}
                bg="#F5F5F5"
                sx={{
                  borderColor: 'lightGreys.2',
                  bg: 'lightGreys.3',
                  width: '100%',
                  borderRadius: 0,
                }}
              />
              <Button
                bg="#F5F5F5"
                sx={{
                  borderRadius: 0,
                }}
              >
                <Text as={IoMdLink} color="black" size={24} />
              </Button>
            </Flex>
          </CopyToClipboard>
        )}
      </Flex>
    </Box>
  );
}

export default CopyVideoLink;
